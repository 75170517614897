/**
 * = Avatars
 */

.avatar + .avatar-content {
	display: inline-block;
	margin-left: .75rem;
}

.avatar-link {
	img{
		width: 4rem;
		height: 4rem;
	}
}

.author-image{
	img{
		width: 3rem;
		height: 3rem;
	}
}


// Overlapped avatars 

.avatar-group {
	.avatar {
		position: relative;
		z-index: 2;
		border: 2px solid $card-bg;
		
		&:hover {
			z-index: 3;
		}
	}

	.avatar + .avatar {
		margin-left: -1rem;
		
	}
}
