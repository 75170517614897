/**
 * = Popovers
 */

.popover {
    border: 0;
}

.hotspot-popover{
    cursor: pointer;
    height: 2.25rem;
    width: 2.25rem;
    position: absolute;
    border:0;
    @include border-radius($circle-radius);
    @include animation-mixin(hotspotPulse, 0, 100%);
    animation: hotspotPulse 1s ease infinite alternate;
}

.popover-header {
	font-weight: $font-weight-bold;
}

// Alternative colors
@each $color, $value in $theme-colors {
    .popover-#{$color} {
        @include popover-variant($value);
    }
}