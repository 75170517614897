/**
 * = Floating animations
 */

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        .floating-#{$breakpoint} {
            animation: floating-#{$breakpoint} 3s ease infinite;
            will-change: transform;

            &:hover {
                animation-play-state: paused;
            }
        }
    }
}

// Keyframes
@keyframes floating-lg {
    0% {
        transform: translateY(0px)
    }
    50% {
        transform: translateY(15px)
    }
    100% {
        transform: translateY(0px)
    }
}

@keyframes floating-md {
    0% {
        transform: translateY(0px)
    }
    50% {
        transform: translateY(10px)
    }
    100% {
        transform: translateY(0px)
    }
}

@keyframes floating-sm {
    0% {
        transform: translateY(0px)
    }
    50% {
        transform: translateY(5px)
    }
    100% {
        transform: translateY(0px)
    }
}