/*

=========================================================
* Rocket - Startup Bootstrap Template
=========================================================

* Product Page: https://themesberg.com/product/bootstrap-themes/rocket-saas-bootstrap-template
* Copyright 2020 Themesberg (https://www.themesberg.com)
* License (https://themesberg.com/licensing)

* Coded by https://themesberg.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap') !important;
// @import url('https://fonts.googleapis.com/css?family=Lora:400i,700i&display=swap') !important;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200 !important;400 !important;600 !important;700 !important;800&display=swap');

// Bootstrap mixins and functions
@import "bootstrap/mixins";
@import "bootstrap/functions";

// Change variables here
@import "rocket/variables";

// Bootstrap
@import "bootstrap/bootstrap";

// Vendor
@import "rocket/vendor";

// Rocket mixins & functions
@import "rocket/mixins"; 
@import "rocket/functions";

// Utilities
@import "rocket/reboot";
@import "rocket/utilities";

// Layout
@import "rocket/layout";

// Components
@import "rocket/components";

// write your custom styles here!

.sidebar {
  min-width: 260px !important;
}

.dzu-dropzone {
  border: 1px solid #e0e0e0 !important;
  border-radius: 0.5rem 0.5rem 0 0 !important;
}

.dzu-dropzoneActive {
  background-color: #deebff !important;
  border-color: #0948b3 !important;
}

.dzu-inputLabel {
  color: #0948b3 !important;
}

.dzu-inputLabelWithFiles {
  background-color: #e6e6e6 !important;
  color: #0948b3 !important;

  border-radius: 0.5rem !important;
}

.dzu-previewContainer {
  border-bottom: 1px solid #ececec !important;
}

.dzu-previewFileName {
  color: #333333 !important;
}

.dzu-previewImage {
  border-radius: 0.5rem !important;
}

.dzu-submitButton {
  background-color: #0948b3 !important;
}

.dzu-submitButton:disabled {
  background-color: #e6e6e6 !important;
  color: #333333 !important;
}

