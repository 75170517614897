/**
 * = Icon boxes
 */

.icon-box{
	padding: $icon-box-padding;
	position: relative;
	
	.icon-box-body{
		position: relative;
	}
	
}

.icon {
	text-align: center;
	display: inline-flex;
	@include align-items(center);
	@include justify-content(center);

	span, svg {
		font-size: $icon-size;
	}

	&.icon-small{
		span,svg{
			font-size: 0.675rem;
		}
	}

	&.icon-xs{
		span, svg {
			font-size: $icon-size-xs;
		}
	}

	&.icon-sm{
		span, svg {
			font-size: $icon-size-sm;
		}
	}

	&.icon-lg{
		span, svg {
			font-size: $icon-size-lg;
		}
		svg{
			height: 2rem;
		}
	}

	&.icon-xl{
		span{
			font-size: $icon-size-xl;
		}
		svg{
			height: 3.5rem;
		}
	}

	&.w-20{
		width: 20px;
	}

	&.w-30{
		width: 30px;
	}

	.organic-shape{
		margin-left:.5rem;
	}

	&.rounded-circle{
		.icon-bordered{
			border-radius:$circle-radius;
		}
	}

	&.organic-radius{
		.icon-bordered{
			border-radius:$organic-radius;
		}
	}
}

// Icons included in shapes
.icon-shape {
	width: $icon-shape;
	height: $icon-shape;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	span, svg {
		font-size: $icon-size;
	}

	&.icon-xs {
		width: $icon-shape-xs;
		height: $icon-shape-xs;
		span, svg {
			font-size: $icon-size-xs;
		}
	}

	&.icon-sm {
		width: $icon-shape-sm;
		height: $icon-shape-sm;
		span, svg {
			font-size: $icon-size-sm;
		}
	}
	
	&.icon-lg{
		width: $icon-shape-lg;
		height: $icon-shape-lg;
		span, svg {
			font-size: $icon-size-md;
		}
	}
}

//Icon Parallax
.image-shape {
    width: $icon-shape-img;
	height: $icon-shape-img;
	position: relative;
	@include icon-image-background(center, cover, no-repeat); 
    span {
        font-size: $icon-size;
		z-index: 1;
	}
	&.icon-parallax{
		@include icon-parallax-background(center, cover, fixed, no-repeat); 
		overflow: hidden;
		background-repeat: no-repeat;
	}
}

.list-icon{
	width: 45px;
}
 
//Colors for icons
@each $color, $value in $theme-colors {
	.icon-shape-#{$color} {
		@include icon-shape-variant(theme-color($color));
	}
	.icon-#{$color} {
		color:$value;
	}
}

.shape-xs {
    width: 10px;
    height: 10px;
}